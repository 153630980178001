import HeaderView from '../../components/Header/HeaderView';

export default function CareerView() {
  return (
    <main>
      <HeaderView />
      <iframe
        src='https://app.stafflow.by/job-vacancy/catlogic/careers'
        title='careers'
        style={{ width: '100vw', height: '100vh', border: 'none' }}
      />
    </main>
  );
}
