import { Col, Row } from 'antd';
import Footer from '../../components/Footer/Footer';
import HeaderView from '../../components/Header/HeaderView';
import styles from './About.module.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Helmet } from 'react-helmet-async';

export default function AboutView() {
  const size = useWindowDimensions();

  const columnSpan = size.width > 500 ? 12 : 24;

  return (
    <main className={styles.area}>
      <Helmet>
        <title>CatLogic | О нас</title>
        <link rel='canonical' href='https://www.catlogic.tech/about' />
      </Helmet>
      <HeaderView />
      <div className={styles.content}>
        <h1>О нас</h1>
        <Row gutter={[24, 24]}>
          <Col span={columnSpan}>
            <div className={styles.card}>
              <p>
                <strong>CatLogic</strong> основана Сергеем Шарковым в 2024 году как ответ на
                потребности рынка в<strong> инновационных ИТ решениях</strong>. Несмотря на свой
                небольшой возраст, компания уже демонстрирует значительные успехи благодаря опыту и
                профессионализму команды.
              </p>
            </div>
          </Col>
          <Col span={columnSpan}>
            <div className={styles.card}>
              <p>
                Почему <strong>CatLogic</strong> — это воплощение ловкости и интуиции в своих
                подходах к бизнес-операциям, подчеркивая гибкость и интуитивное понимание
                потребностей организаций любого размера. Вдохновленные прозорливостью кошек, мы
                внедряем логику и аналитику в стратегическое планирование, опираясь на
                технологические инновации, включая <strong>искусственный интеллект</strong>, для
                усовершенствования процессов и операций.
                <br />
                <br />
                CatLogic стремится создавать решения, которые отличаются универсальностью и
                масштабируемостью, предоставляя при этом исключительный пользовательский опыт и
                способствуя эффективному развитию бизнеса.
              </p>
            </div>
          </Col>
          <Col span={columnSpan}>
            <div className={styles.placeholder} />
          </Col>
          <Col span={columnSpan}>
            <div className={styles.card}>
              <p>
                <strong>CatLogic</strong> объединила высококвалифицированных профессионалов в
                области разработки программного обеспечения, дизайна, управления проектами и ИИ,
                чтобы создать продукт, который не только удовлетворяет текущие потребности рынка, но
                и <strong>предвосхищает будущие тенденции</strong>.
                <br />
                <br />
                Все члены команды имеют многолетний опыт в своей профессиональной карьере, в том
                числе за рулем CatLogic стоит основатель, чей богатый{' '}
                <strong>тридцатилетний опыт в IT-бизнесе</strong> формирует наш прогрессивный курс.
                От зарождения технологических стартапов до управления зрелыми предприятиями, его
                острое видение лежит в основе нашего стремления к постоянному
                <strong> развитию и инновациям</strong>.
              </p>
            </div>
          </Col>
          <Col span={columnSpan}>
            <div className={styles.card}>
              <p>
                Сегодня, работая над нашим флагманским продуктом, мы придерживаемся философии
                <strong> инноваций, качества и скорости</strong>. Мы верим, что наше решение не
                только поможет компаниям, но и сделает их более <strong>эффективными</strong>,
                справедливыми и приятными для всех участников.
              </p>
            </div>
          </Col>
          <Col span={columnSpan}>
            <div className={styles.card}>
              <p>
                В <strong>CatLogic</strong> мы уверены, что наша <strong>команда</strong>, наш{' '}
                <strong>опыт</strong> и наша <strong>страсть к технологиям</strong> позволят нам
                внести значительный вклад в развитие индустрии технологий и создать новые
                возможности для бизнеса на
                <strong> глобальном</strong> уровне.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </main>
  );
}
